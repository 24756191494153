import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma d’estrella, de 3 a 8 cm de diàmetre. Quan és jove està tancat i amb l’edat s’obre diferenciant-se tres estrats del peridi(envoltura de l’aparell esporífer): exoperidi, mesoperidi i endoperidi. L’exoperidi correspon a la part exterior del carpòfor i està fragmentat en 5-7 braços incurvats, més o manco triangulars, denominats lacínies, el que li dóna un aspecte d’estrella. El mesoperidi es presenta com una capa carnosa sovint formant una espècie de collar molt típic. L’endoperidi és sèssil, subglobós, membranós, de 2 a 4 cm, de color crema. La gleva és de color blanquinosa quan el bolet és jove i va tornant marró obscur amb l’edat. Les espores són de color xocolata en massa, rodones i cobertes de petites berruguetes cilíndriques, de 3- 5,4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      